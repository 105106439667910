import axios from 'axios';
import { scheduleKey as KEY } from '../../schemas/config';
import normalise from '../../schemas/normalizr';
import { AWS_ENDPOINT, EPG_REGION } from '../../constants';
import * as TYPES from './schedule.types';
import selectScheduleItems from './schedule.selectors';
import { Dispatch } from 'redux';
import { State } from './schedule';

type GetState = () => State;

/**
 * FetchScheduleData from API and spread into store.
 *
 * @param {string} date
 */
export default function fetchScheduleData(date: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    // Return from state if it already exists.
    const state = getState();
    const scheduleItems = selectScheduleItems(state, date);
    if (scheduleItems && scheduleItems.length > 0) {
      dispatch({ type: TYPES.EXIST });
      return scheduleItems;
    }

    dispatch({ type: TYPES.LOAD, payload: true });

    try {
      const url = `${AWS_ENDPOINT}/${EPG_REGION}/${date}.json`;

      const response = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        withCredentials: false,
      });

      if (response.status !== 200) {
        console.error('Request failed:', response);
        dispatch({ type: TYPES.LOAD_FAIL });
        return false;
      }

      const payload = normalise(response.data, KEY);
      dispatch({ type: TYPES.ADDED, payload });

      return true;
    } catch (error: any) {
      console.error('Axios Error:', error);

      if (axios.isAxiosError(error)) {
        console.error('Axios Response Error:', error.response?.data);
      }

      dispatch({ type: TYPES.LOAD_FAIL });
      return false;
    }
  };
}
